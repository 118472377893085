<template>
    <div class="pagebox" v-loading="loading" element-loading-text="加载中...">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <!-- 院校比较 -->
        <div class="college_compare_fixed">
            <div class="compare_head_fixed" :class="TableHeaderFixed">
                <div class="compare_head_con">
                    <table cellspacing="0">
                        <tr>
                            <td style="width:136px;">
                                <div class="txt">
                                    <div>共 <span style="color:rgba(var(--themecolor));font-size:18px;">5</span> 所院校</div>
                                    <div><el-checkbox v-model="OnlyLookUnlike" @change="OnlyLookUnlikeChange">只看差异</el-checkbox></div>
                                </div>
                            </td>
                            <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareTable" :key="index">
                                <div class="txt">
                                    <div class="logo" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/logo/'+(item.Id)+'.jpg) no-repeat center center / cover'}"></div>
                                    <div class="sname">{{item.CollegeName}}</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="college_compare_list">
            <div class="compare_list_con" id="table_table">
                <table cellspacing="0">
                    <tr>
                        <td style="width:136px;"><div class="txt">院校类别</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">省份名称</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">城市名称</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">主管部门</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">院校属性</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">院校标签</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">211,985,保研,博士授权学科,国重点,建筑新八校,九校联盟,双一流,研究生院,中国高校行星科学联盟,卓越法律,卓越工程师,卓越医生 </div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">211,985,保研,博士授权学科,电气二龙四虎,国重点,建筑新八校,九校联盟,双一流</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">研究生院,中国高校行星科学联盟,卓越法律,卓越工程师,卓越医生</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">国重点,双一流,研究生院</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">保研,博士授权学科,国重点,建筑新八校,九校联盟,双一流,研究生院</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">学生男女占比</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">院校类别</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">综合</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">省份名称</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">四川省</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">城市名称</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">成都市</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">主管部门</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">教育部</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">院校属性</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">公立</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">院校标签</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">211,985,保研,博士授权学科,国重点,建筑新八校,九校联盟,双一流,研究生院,中国高校行星科学联盟,卓越法律,卓越工程师,卓越医生 </div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">211,985,保研,博士授权学科,电气二龙四虎,国重点,建筑新八校,九校联盟,双一流</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">研究生院,中国高校行星科学联盟,卓越法律,卓越工程师,卓越医生</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">国重点,双一流,研究生院</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">保研,博士授权学科,国重点,建筑新八校,九校联盟,双一流,研究生院</div></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">学生男女占比</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}"><div class="txt">60%(男)：40%(女)</div></td>
                    </tr>
                </table>
                <div class="mark">（ 以上信息仅供参考，详细数据以院校官方公布信息为准 ）</div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            OnlyLookUnlike:false,
            TableHeaderFixed:'',
            CollegeCompareTableTdWidth:0,
            CollegeCompareTable:[],
        }
    },
    mounted(){
        this.GetCollegeList();// 获取院校
        
        let w = document.getElementById('table_table').clientWidth;
        this.CollegeCompareTableTdWidth = ((w - 137) / 5) - 1;
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            if (ddeht > 140) {
                this.TableHeaderFixed='fixed';
            }
            else {
                this.TableHeaderFixed='';
            }
        }, true);
    },
    methods:{
        // 只看差异
        OnlyLookUnlikeChange(val){
            console.log(val)
        },
        // 获取院校
        GetCollegeList(){
            this.CollegeCompareTable = [
                {Id:'1',CollegeName:'四川农业大学'},
                {Id:'2',CollegeName:'四川师范大学'},
                {Id:'3',CollegeName:'四川电子机械职业技术学院'},
                {Id:'4',CollegeName:'四川轻化工大学'},
                {Id:'5',CollegeName:'四川外国语大学'},
            ];
        },
    }
}
</script>
<style scoped lang="less">
.college_compare_fixed{
    width: 100%;
    margin-top: 20px;
    height: 90px;
    .compare_head_fixed.fixed{
        position: fixed;
        top: 40px;
        left: 0px;
        z-index: 99;
    }
    .compare_head_fixed{
        width: 100%;
        height: 90px;
        .compare_head_con{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            table{
                margin: 0px 20px;
                table-layout: fixed;
                background-color: #f7f7f7;
                border: 1px solid #ddd;
                border-left: none;
                tr{
                    td{
                        height: 88px;
                        border-left: 1px solid #ddd;
                        color: #666;
                        .txt{
                            margin: 0px 10px;
                            text-align: center;
                            .logo{
                                width: 40px;
                                height: 40px;
                                overflow: hidden;
                                margin: auto;
                            }
                            .sname{
                                font-size: 16px;
                                font-weight: bold;
                                letter-spacing: 1px;
                                margin-top: 5px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.college_compare_list{
    max-width: 1420px;
    margin: 0px auto 50px;
    user-select: none;
    .compare_list_con{
        margin: 0px 20px;
        table{
            table-layout: fixed;
            border-right: 1px solid #ddd;
            background-color: #fff;
            tr{
                td{
                    border-bottom: 1px solid #ddd;
                    border-left: 1px solid #ddd;
                    color: #777;
                    font-size: 14px;
                    padding: 10px 0px;
                    line-height: 20px;
                    vertical-align: top;
                    text-align: center;
                    .txt{
                        margin: 0px 10px;
                    }
                }
            }
            tr td:first-child{
                width: 136px;
                font-weight: bold;
                vertical-align:middle;
                background-color: #f7f7f7;
            }
        }
        .mark{
            color: #888;
            font-size: 12px;
            margin-top: 20px;
        }
    }
}
</style>
<style>
.college_compare_fixed .compare_head_fixed .el-checkbox{
    margin-top: 10px;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__inner{
    border: 1px solid #c2bfbf;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: #c2bfbf;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__input.is-checked .el-checkbox__inner, .college_compare_fixed .compare_head_fixed .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: rgba(var(--themecolor),0.75);
    border-color: rgba(var(--themecolor),0.75);
}
.college_compare_fixed .compare_head_fixed .el-checkbox__label{
    padding-left: 5px;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__input.is-checked + .el-checkbox__label{
    color:#666;
}
</style>